<template>
  <v-container fluid class="app-status-cart" :class="style">
    <v-row no-gutters align="center">
      <v-col cols="12" sm="4">
        <div class="app-status-cart__total">
          <div class="app-status-cart__total--icon">
            <IconMoneyAccountingCalculator />
          </div>
          <div class="app-status-cart__total--body">
            <div class="title">Prix total du projet</div>
            <div class="subtitle">{{ total }} € TTC</div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div v-if="$route.name !== 'QuotaSum'" class="app-status-cart__status">
          <IconQuotaEditStatus />
          <router-link :to="routerLink" class="app-status-cart__status--text"
            >Voir mes projets</router-link
          >
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="app-status-cart__validation">
          <IconQuotaValidation />
          <div class="app-status-cart__validation--text">
            {{ $store.getters["quota/getQuotaStatusText"] }}
          </div>

          <IconQuotaEdit v-if="status === 1 || status === 2" />
          <IconQuotaNotValid
            v-else-if="status === 3 || status === 4"
            class="ml-2"
          />
          <v-icon v-else-if="status === 5" color="success" class="ml-n2" small
            >mdi-check-circle</v-icon
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppStatusCart",
  components: {
    IconMoneyAccountingCalculator: () =>
      import("~cp/Icons/IconMoneyAccountingCalculator"),
    IconQuotaNotValid: () => import("~cp/Icons/IconQuotaNotValid"),
    IconQuotaValidation: () => import("~cp/Icons/IconQuotaValidation"),
    IconQuotaEditStatus: () => import("~cp/Icons/IconQuotaEditStatus"),
    IconQuotaEdit: () => import("~cp/Icons/IconQuotaEdit"),
  },
  computed: {
    style() {
      return [this.$route.name !== "QuotaSum" ? "grey-lighten-4" : ""];
    },
    total() {
      let price = this.$store.getters["quota/getTotal"];
      return price.toFixed(2);
    },
    isQuotaValid() {
      return this.$store.getters["quota/getQuotaStatus"];
    },
    status() {
      return this.$store.getters["quota/getQuotaStatus"];
    },
    routerLink() {
      return {
        name: "Home",
      };
    },
  },
};
</script>

<style src="./AppStatusCart.scss" lang="scss" scoped></style>